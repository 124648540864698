function faqInit() {
  $(".js-faq-link").on("click", function(e) {
    e.preventDefault();
    var cont = $(this).parents(".js-faq-item");
    var content = cont.find(".js-faq-content");
    var item = $(this);
    if (item.hasClass("is-open")) {
      content.slideUp(300, function(){
        item.removeClass("is-open")
      });
    } else {
      content.slideDown(300);
      item.addClass("is-open")
    }


  })
}

const APP = {
	name: 'iBrush HTML Starter'
};
function popup(){
  var scrollbarWidth = window.innerWidth - document.body.clientWidth;
  if (scrollbarWidth > 25) {
    scrollbarWidth = 0;
  }
  var popup =$(".js-popup");
  var shadow = $(".popup-shadow");
  var open = $(".js-popup-open");
  var close = $(".js-popup-close");
  popup.css({
    "display": "none",
    "opacity": "1"
  })
  open.on("click", function(e){
    e.preventDefault();
    openPopup();
  })
  shadow.on("click", function(){
    closePopup();
  })
  close.on("click", function(){
    closePopup();
  })


  function openPopup() {
    $(".js-popup").fadeIn();
    $(".popup-shadow").fadeIn();
    $("body").addClass("no-overflow");
    $("body").css({
      "padding-right": scrollbarWidth + "px"
    })
  }

  function closePopup() {
    $(".popup").fadeOut();
    $(".popup-shadow").fadeOut();
    $("body").removeClass("no-overflow");
    $("body").css({
      "padding-right": "0"
    })
  }


}

function faqInit() {
  $(".js-faq-link").on("click", function(e) {
    e.preventDefault();
    var cont = $(this).parents(".js-faq-item");
    var content = cont.find(".js-faq-content");
    var item = $(this);
    if (item.hasClass("is-open")) {
      content.slideUp(300, function(){
        item.removeClass("is-open")
      });
    } else {
      content.slideDown(300);
      item.addClass("is-open")
    }


  })
}

function mobMenu(){
  var scrollbarWidth = window.innerWidth - document.body.clientWidth;
  if (scrollbarWidth > 25) {
    scrollbarWidth = 0;
  }
  var menu = $(".js-menu");
  var shadow = $(".popup-shadow");
  var open = $(".js-menu-open");
  var close = $(".js-menu-close");
  open.on("click", function(){
    mobOpen();
  })
  close.on("click", function(){
    mobClose();
  })
  shadow.on("click", function(){
    if ($(".js-menu").hasClass("menu-opened")) {
      mobClose();
    }
  })

  function mobOpen(){
    $("body").css({
      "padding-right": scrollbarWidth + "px"
    })
    shadow.addClass("--transparent")
    shadow.fadeIn(300);
    menu.addClass("menu-opened");
    $("body").addClass("no-overflow");
  }

  function mobClose(){
    shadow.fadeOut(300, function(){
      shadow.removeClass("--transparent");
    });

    menu.removeClass("menu-opened");
    $("body").removeClass("no-overflow");
    $("body").css({
      "padding-right": "0"
    })
  }
}

function slider() {
  $('.js-slider-inner').not('.slick-initialized').each(function() {
    const $this = $(this);
    
    console.log($this.closest('.js-reviews-slider').find('.js-reviews-slider-nav').length);

    $this.slick({
      appendArrows: $this.closest('.js-reviews-slider').find('.js-reviews-slider-nav'),
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  });
}

function scrollTo() {
  var menu = $(".js-menu");
  var shadow = $(".popup-shadow");
  $(".js-scrollto").on("click", function(e){
    e.preventDefault();
    var obj = $(this);
    var target = $(this).attr("href");
    if (menu.hasClass("menu-opened")){
      mobClose();
    }

    $("html,body").animate({scrollTop:$(target).offset().top - $('.js-top').outerHeight()}, 500, function(){
      if (obj.hasClass("js-to-faq") && !$(target).find(".js-faq-link").hasClass("is-open")) {
        $(target).find(".js-faq-link").eq(0).trigger("click");
      }
    });

  })

  function mobClose(){
    menu.css({
      "transform": "translateX(150%)"
    })
    shadow.fadeOut(300);
    menu.removeClass("menu-opened");
    $(".popup-shadow").removeClass("--transparent")
    $("body").removeClass("no-overflow");
  }
}





function expand() {
  $(".js-expandable").on("click", function(){
    var expandable = $(this).find("span");
    var moreText = $(this).find(".js-more-link");
    $(this).toggleClass("is-open");
    expandable.slideToggle(300);
  })
}

$(function() {
  svg4everybody();
  faqInit();
  mobMenu();
  popup();
  scrollTo();
  expand();
  slider();

  AOS.init();

  toggleHeader();

  $(window).on('scroll', toggleHeader);

  function toggleHeader() {
    const scroll = $(window).scrollTop();
    const activeCls = 'is-active';

    if (scroll > 0) {
      $('.js-top').addClass(activeCls);
    } else {
      $('.js-top').removeClass(activeCls);
    }
  }
});


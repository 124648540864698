function mobMenu(){
  var scrollbarWidth = window.innerWidth - document.body.clientWidth;
  if (scrollbarWidth > 25) {
    scrollbarWidth = 0;
  }
  var menu = $(".js-menu");
  var shadow = $(".popup-shadow");
  var open = $(".js-menu-open");
  var close = $(".js-menu-close");
  open.on("click", function(){
    mobOpen();
  })
  close.on("click", function(){
    mobClose();
  })
  shadow.on("click", function(){
    if ($(".js-menu").hasClass("menu-opened")) {
      mobClose();
    }
  })

  function mobOpen(){
    $("body").css({
      "padding-right": scrollbarWidth + "px"
    })
    shadow.addClass("--transparent")
    shadow.fadeIn(300);
    menu.addClass("menu-opened");
    $("body").addClass("no-overflow");
  }

  function mobClose(){
    shadow.fadeOut(300, function(){
      shadow.removeClass("--transparent");
    });

    menu.removeClass("menu-opened");
    $("body").removeClass("no-overflow");
    $("body").css({
      "padding-right": "0"
    })
  }
}
